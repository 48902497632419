<template>

<!---
  <div class="fixedElement">
    <div class="nn">
    <div class="progress" role="progressbar" style="height: 1em; border-radius: 0px;">
      <div v-if="(this.notZero != this.sumOfAllElements)" class="progress-bar" :style="{width: calcPercentage() + '%'}">{{ this.notZero }}/{{ this.sumOfAllElements }}</div>
      <div v-if="this.notZero == this.sumOfAllElements" class="progress-bar bg-success" :style="{width: calcPercentage() + '%'}">Все {{ this.sumOfAllElements }} пунктов отмечены!</div>
    </div>
    </div>
    <div class="container">
    <br>
    <div class="row">
      <div class="col">
        <h2>💦 тест</h2>
      </div>
      <div class="col" style="text-align: right;">
        <span v-if="isSaving">
          <div class="spinner-border" role="status" style="height: 1em; width: 1em;">
            <span class="visually-hidden">Loading...</span>
          </div>
          сохраняю...
        </span>
        <button class="btn btn-primary m-1" v-on:click="this.clearStorage()">очистить</button>
      </div>
    </div>
    <br>
    </div>
</div>
-->


  <div class="container" v-if="!this.savedGameId">
    <br>

    <div v-for="(category, catIndex) in this.json" v-bind:key="category">
      <h3>{{ category['name'] }}</h3>
      <div v-for="(element, elIndex) in category['elements']" v-bind:key="element">
        <div class="row">

          <!-- Desktop -->
          <div class="col-md d-none d-md-block">
            <h4>{{ element['emoji'] }} <u>{{ element['name'] }}</u></h4>
            <small>{{ element['help'] }}</small>
          </div>
          <!-- Desktop End -->

          <!-- Mobile -->
          <!--
          <div class="col-md d-xl-none d-md-none" style="text-align: center;">
            <h4 v-if="element['value1'] !== '' && element['value2'] !== ''" style="text-decoration: line-through;">{{ element['emoji'] }} <u>{{ element['name'] }}</u></h4>
            <h4 v-if="element['value1'] == '' || element['value2'] == ''">{{ element['emoji'] }} <u>{{ element['name'] }}</u></h4>
            <small hidden>{{ element['help'] }}</small>
          </div>-->
          <!-- Mobile End -->

          <div class="col" style="text-align: center;">
            <span class="rateDescription">{{ element['description1'] }}</span>
            <h2>
              <span class="emoji" v-if="element['value1'] != 0" @click="this.json[catIndex]['elements'][elIndex]['value1'] = 0">🫥</span>
              <span class="emoji-selected" v-if="element['value1'] == 0" @click="this.json[catIndex]['elements'][elIndex]['value1'] = 0">🫥</span>
              <span> </span>

              <span class="emoji" v-if="element['value1'] != 1" @click="this.json[catIndex]['elements'][elIndex]['value1'] = 1">😖</span>
              <span class="emoji-selected" v-if="element['value1'] == 1" @click="this.json[catIndex]['elements'][elIndex]['value1'] = 1">😖</span>
              <span> </span>

              <span class="emoji" v-if="element['value1'] != 2" @click="this.json[catIndex]['elements'][elIndex]['value1'] = 2">😐</span>
              <span class="emoji-selected" v-if="element['value1'] == 2" @click="this.json[catIndex]['elements'][elIndex]['value1'] = 2">😐</span>
              <span> </span>
              <span class="emoji" v-if="element['value1'] != 3" @click="this.json[catIndex]['elements'][elIndex]['value1'] = 3">🤔</span>
              <span class="emoji-selected" v-if="element['value1'] == 3" @click="this.json[catIndex]['elements'][elIndex]['value1'] = 3">🤔</span>
              <span> </span>
              <span class="emoji" v-if="element['value1'] != 4" @click="this.json[catIndex]['elements'][elIndex]['value1'] = 4">😌</span>
              <span class="emoji-selected" v-if="element['value1'] == 4" @click="this.json[catIndex]['elements'][elIndex]['value1'] = 4">😌</span>
              <span> </span>
              <span class="emoji" v-if="element['value1'] != 5" @click="this.json[catIndex]['elements'][elIndex]['value1'] = 5">😍</span>
              <span class="emoji-selected" v-if="element['value1'] == 5" @click="this.json[catIndex]['elements'][elIndex]['value1'] = 5">😍</span>
            </h2>



          </div>
          <div class="col" style="text-align: center;">
            <span class="rateDescription">{{ element['description2'] }}</span>
            <h2>
              <span class="emoji" v-if="element['value2'] != 0" @click="this.json[catIndex]['elements'][elIndex]['value2'] = 0">🫥</span>
              <span class="emoji-selected" v-if="element['value2'] == 0" @click="this.json[catIndex]['elements'][elIndex]['value2'] = 0">🫥</span>
              <span> </span>

              <span class="emoji" v-if="element['value2'] != 1" @click="this.json[catIndex]['elements'][elIndex]['value2'] = 1">😖</span>
              <span class="emoji-selected" v-if="element['value2'] == 1" @click="this.json[catIndex]['elements'][elIndex]['value2'] = 1">😖</span>
              <span> </span>

              <span class="emoji" v-if="element['value2'] != 2" @click="this.json[catIndex]['elements'][elIndex]['value2'] = 2">😐</span>
              <span class="emoji-selected" v-if="element['value2'] == 2" @click="this.json[catIndex]['elements'][elIndex]['value2'] = 2">😐</span>
              <span> </span>
              <span class="emoji" v-if="element['value2'] != 3" @click="this.json[catIndex]['elements'][elIndex]['value2'] = 3">🤔</span>
              <span class="emoji-selected" v-if="element['value2'] == 3" @click="this.json[catIndex]['elements'][elIndex]['value2'] = 3">🤔</span>
              <span> </span>
              <span class="emoji" v-if="element['value2'] != 4" @click="this.json[catIndex]['elements'][elIndex]['value2'] = 4">😌</span>
              <span class="emoji-selected" v-if="element['value2'] == 4" @click="this.json[catIndex]['elements'][elIndex]['value2'] = 4">😌</span>
              <span> </span>
              <span class="emoji" v-if="element['value2'] != 5" @click="this.json[catIndex]['elements'][elIndex]['value2'] = 5">😍</span>
              <span class="emoji-selected" v-if="element['value2'] == 5" @click="this.json[catIndex]['elements'][elIndex]['value2'] = 5">😍</span>
            </h2>
          </div>
        </div>
        <br>
      </div>
      <hr>
    </div>

    <br>

    <div class="progress" role="progressbar" style="height: 2em; border-radius: 16px; width: 100%; ">
      <div v-if="(this.notZero != this.sumOfAllElements)" class="progress-bar" :style="{width: calcPercentage() + '%'}">{{ this.notZero }}/{{ this.sumOfAllElements }}</div>
      <div v-if="this.notZero == this.sumOfAllElements" class="progress-bar bg-success" :style="{width: calcPercentage() + '%'}">Все {{ this.sumOfAllElements }} пунктов отмечены!</div>
    </div>

    <br>
    <div class="row" v-if="!this.isResultSavingOnServer">
      <div class="col">
        <button class="btn btn-outline-danger me-2" type="button" v-on:click="this.clearStorage()"><i class="bi bi-trash"></i> начать заново</button>
      </div>
      <div class="col" style="text-align: end;">
        <button class="btn btn-outline-success me-2" type="button" v-on:click="this.saveDataToServer()">готово!</button><br>
      </div>
    </div>

    <div v-if="this.isResultSavingOnServer" style="text-align: center">
      <span>
            <div class="spinner-border" role="status" style="height: 2em; width: 2em;">
              <span class="visually-hidden">Loading...</span>
            </div>
          <br>
          <br>
      </span>
    </div>
    <br>
    <br>
    <br>
  </div>

  <div class="container" v-if="this.savedGameId">

    <h3>Результаты сохранены! Отправьте ссылку своему партнеру чтобы проверить совместимость! https://drusha.me/kink/data/{{ this.savedGameId }}.json</h3>

    <button class="btn btn-lg btn-outline-success me-2" type="button" v-on:click="this.clearStorage()">Пройти ещё раз</button><br><br>
    <button class="btn btn-lg btn-outline-success me-2" type="button" v-on:click="this.savedGameId = null">Погодите, я ещё не закончил...</button>

    <br>
    <div v-if="this.compatibility[0].length > 0"><h2>высокая совместимость:</h2>
      <span v-for="(element, index) in this.compatibility[0]" :key="index"><span class="badge rounded-pill bg-secondary me-2">{{ element }}</span></span>
    </div>

    <div v-if="this.compatibility[1].length > 0"><h2>cредняя совместимость:</h2>
      <span v-for="(element, index) in this.compatibility[1]" :key="index"><span class="badge rounded-pill bg-secondary me-2">{{ element }}</span></span>
    </div>

    <div v-if="this.compatibility[3].length > 0"><h2>плохая совместимость:</h2>
      <span v-for="(element, index) in this.compatibility[3]" :key="index"><span class="badge rounded-pill bg-secondary me-2">{{ element }}</span></span>
    </div>

    <br>

    <button class="btn btn-outline-success me-2" type="button">Получить подробный отчёт о Вашем результате 2,49$</button><br><br>
    <button class="btn btn-outline-success me-2" type="button">Получить подробный парный отчёт 4,99$</button><br>

  </div>

</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      value: 0,
      json: [],
      isSaving: false,
      notZero: 0,
      sumOfAllElements: 0,
      savedGameId: null,
      isResultSavingOnServer: false,
      refferId: "a458fb6357",
      refferJson: [],
      compatibility: [[],[],[],[],[],[]]
    }
  },
  watch: {
    json : {
      handler(){
        console.log("Сохраняюсь...")
        this.toggleSaving()
        this.countElements()
        localStorage.setItem("data", JSON.stringify(this.json))
      },
      deep: true
    }
  },
  mounted() {
    const storageGame = localStorage.getItem("data")
    console.log(storageGame)
    if(storageGame == "[]" || storageGame == null){
      console.log("Игра пустая, запускаем новую")
      this.fetchGame()
      localStorage.setItem("data", JSON.stringify(this.json))
    } else {
      console.log("Запускаем сохранение")
      this.json = JSON.parse(storageGame)
    }

    if(this.refferId){
      this.fetchReffer()
    }
  },
  methods: {
    async fetchGame(){
      try {
        const res = await fetch('emptyGame.json');
        if (!res.ok) {
          this.json = []
        }
        const data = await res.json();
        this.json = data
        console.log(this.json)
      } catch (error) {
        this.json = []
        console.error('There was a problem with the fetch operation:', error);
      }
    },

    async fetchReffer(){
      try {
        const res = await fetch('https://drusha.me/kink/data/' + this.refferId + '.json');
        if (!res.ok) {
          this.refferJson = []
        }
        const data = await res.json();
        this.refferJson = data
      } catch (error) {
        this.refferJson = []
        console.error('There was a problem with the fetch operation:', error);
      }
    },
    toggleSaving(){
      this.isSaving = true
      setTimeout(() => {
        this.isSaving = false
      }, 500)
    },
    calculateCompatibility(){
      console.log("hello!")
      // iterate over this.refferJson
      // iterate over this.json
      // difference between values
      // 0 - great compatibility
      // 1 - like
      // 2 -
      // 3 -
      // 4 -
      // more - ignore

      this.compatibility = [[],[],[],[],[],[]]
      for (const category of this.refferJson) {
        for (const category2 of this.json) {
          if(category['category'] == category2['category']){
            for (const element of category2['elements']) {
              for (const element2 of category['elements']) {
                if (element['name'] == element2['name']) {
                  let a = Math.abs(element['value1'] - element2['value1'])
                  if (a == 0 || a == 1) {
                    this.compatibility[0].push(element['emoji']+" "+element['name'])
                  }
                  if (a == 2 || a == 3) {
                    this.compatibility[2].push(element['emoji']+" "+element['name'])
                  }
                  if (a > 3) {
                    this.compatibility[4].push(element['emoji']+" "+element['name'])
                  }
                }
              }
            }
          }
        }
      }
      console.log(this.compatibility)
    },
    async saveDataToServer(){
      this.isResultSavingOnServer = true
      // post this.json to the https://drusha.me/kink/ endpoint
      const res = await fetch('https://drusha.me/kink/', {
        method: 'POST',
        // data stored in data parameter
        body: JSON.stringify(this.json)
      });

      if (!res.ok) {
        console.error('There was a problem with the fetch operation:', res.status);
        this.isResultSavingOnServer = false
        return;
      }

      const data = await res.text();
      this.savedGameId = data
      this.isResultSavingOnServer = false

      this.calculateCompatibility()
    },
    countElements(){
      let sumOfAllElements = 0
      let notZero = 0
      for (const category of this.json) {
        for (const element of category['elements']) {
          if(element['value1'] !== ""){
            notZero += 1
          }
          if(element['value2'] !== ""){
            notZero += 1
          }
          sumOfAllElements += 2
        }
      }
      this.sumOfAllElements = sumOfAllElements
      this.notZero = notZero
    },
    calcPercentage(){
      return Math.round((this.notZero / this.sumOfAllElements) * 100)
    },
    clearStorage(){
      localStorage.setItem("data", "[]")
      this.savedGameId = null
      this.fetchGame()
    }
  }
}
</script>

<style>
body,html{
  font-family: "Ubuntu Mono", monospace;
  font-weight: 400;
  font-style: normal;
  padding-top: 15px;
  font-size: large;
  background-color: #F4F3F2;
  color: #1d1d1d;
}

.emoji{
  filter: grayscale();
  cursor: pointer;
}
.emoji-selected{
  filter: none;
  cursor: pointer;
}
.emoji:hover{
  filter: none;
}
.rateDescription{
  font-size: 0.8em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
          line-clamp: 1;
  -webkit-box-orient: vertical;
}

.fixedElement {
    background-color: #F4F3F2;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    position:fixed;
    top:0;
    width:100%;
    z-index:100;
}
.navbar{
  padding-top: 0px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}
</style>
